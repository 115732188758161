@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root {
  height: 100%;
  width: 100%;
  overflow: hidden;
  cursor: url('assets/cursor.png') 16 16,auto;
}


*::-webkit-scrollbar
{
  width: 16px;
  background-color: transparent;
  left: 0;
  position: absolute;
}

*::-webkit-scrollbar-track
{
  border-radius: 0.30vw;
  background-color: #ffffff20;
}

*::-webkit-scrollbar-thumb
{
  border-radius: 0.20vw;
  background-color: #0F0C0C90;
}

.inversed {
  direction: rtl;
}

.notinversed {
  direction: ltr;
}

@font-face {
  font-family: 'kaijukingz';
  src: url('assets/fonts/KaijuKingz.woff2') format('woff2'),
       url('assets/fonts/KaijuKingz.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'perfectDOS';
  src: url('assets/fonts/PerfectDOS.woff2') format('woff2'),
       url('assets/fonts/PerfectDOS.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'retrogaming';
  src: url('assets/fonts/Retro Gaming.woff2') format('woff2'),
       url('assets/fonts/Retro Gaming.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.icon:hover {
  filter: brightness(0%) invert(69%) sepia(72%) saturate(419%) hue-rotate(284deg) brightness(95%) contrast(96%);
}

.bg-connect {
  background:url("assets/connect.png");
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
}
.bg-connect:hover {
  background:url("assets/connect_clic.png");
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
}

.bg-search {
  background:url("assets/search.png");
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
}
.bg-search:hover {
  background:url("assets/search_clic.png");
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
}

.shadow {
  text-shadow: 0 0.35vh #724E81;
}
.shadow_sm {
  text-shadow: 0 0.2vh #724E81;
}

@keyframes bubble {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0, -1.75rem, 0);
    opacity: 0;
  }
}
.bubble {
  animation: bubble 7s infinite;
}

@keyframes creggers {
  0% {
    left: 0%;
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    left: 80%;
    opacity: 0;
  }
}
.creggers {
  animation: creggers 10s ease-in-out infinite;
}

.rendering-pixelated { image-rendering: pixelated; }
